export const available = [
  {
    id: 1,
    title: "What is Lorem Ipsum?",
    subtitle: "Lorem Ipsum is simply dummy",
  },
  {
    id: 2,
    title: "Why do we use it?",
    subtitle: "The point of using at its layout",
  },
  {
    id: 3,
    title: "Where does it come from?",
    subtitle: "Contrary to popular belief",
  },
  {
    id: 4,
    title: "Where can I get some?",
    subtitle: "There are many variations",
  },
  {
    id: 5,
    title: "Morbi sagittis tellus a efficitur",
    subtitle: "Etiam mollis eros eget mi.",
  }
]